<template>
  <div>
    <v-toolbar
      v-if="userInfo && getMyOrganizationUser"
      flat
      class="elevation-3"
      height="72"
      rounded
    >
      <img
        v-if="userInfo.organization.logo && userInfo.organization.logo.id && userInfo.organization.logo.path"
        :src="userInfo.organization.logo.path"
        :alt="userInfo.organization.title"
        class="admin-header__logo"
      >
      <img
        v-else
        :alt="organization.title"
        src="@/assets/img/organization-logo.png"
        class="organization-header__logo"
      >
      <v-toolbar-title

        class="text-left hidden-xs-only"
      >
        <span>
          {{ getTitle }}
        </span>
        <v-list-item-subtitle v-if="office">
          <v-icon>mdi-map-marker</v-icon>
          <span
            style="color: rgba(0, 0, 0, 0.61)"
            class="ml-1"
          >{{ office.address }} </span>
        </v-list-item-subtitle>
      </v-toolbar-title>
      <v-spacer />
      <v-card-actions>
        <v-btn
          outlined
          rounded
          color="success"
          class="py-6 text-left"
        >
          <div>
            <div
              class=" organization__sum "
              style="margin: 0 !important; color: black"
            >
              {{ getAmount | numberToBeautifulNumber }} ₽
            </div>
            <div
              v-if="!myOrganizationIsOfferLess"
              class="organization-offer-number"
              style="text-align: center"
            >
              {{ getOfferNumber }}
              <span style="text-transform: none">{{ pluriaizeOffers(getOfferNumber) }}</span>
            </div>
          </div>
        </v-btn>
        <div
          v-if="!myOrganizationIsFinanceLess"
          class="header__admin__stats"
          style="margin-right: 16px"
        >
          <v-btn
            class="mb-1"
            color="success"
            text
            small
            rounded
            outlined
            style="border-color: #8bd118"
            @click.native="showModalFormBuy"
          >
            Пополнить
          </v-btn>
          <v-btn
            v-if="isOrganizationAdminGroup"
            color="success"
            style="border-color: #8bd118"
            text
            small
            rounded
            outlined
            @click.native="showModalTransferMoney"
          >
            Перевести
          </v-btn>
        </div>
      </v-card-actions>
    </v-toolbar>
    <v-card-actions v-if="isOrganizationRoot && showOrganizationBalance">
      <span>Баланс компании:</span>
      <v-spacer />
      <span>
        <span class="balance-value__title">Свободные офферы:</span>
        {{ getOrganizationOffers }}
      </span>
      <span class="ml-3">
        <span class="balance-value__title">Нераспределенный баланс:</span>
        {{ getOrganizationAmount | numberToBeautifulNumber }} ₽
      </span>
    </v-card-actions>
  </div>
</template>

<script>
import { pluriaizeOffers } from '@/utils/plural-util'
import userUtil from '@/utils/user-util'

export default {
  name: 'PageHeader',
  props: {
    showOrganizationBalance: {
      type: Boolean,
      default: true
    },
    enableRefillMoney: {
      type: Boolean,
      default: true
    },
    enableRefillOffer: {
      type: Boolean,
      default: true
    },
    isModalHeader: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    valueMoney () {
      const value = this.getAmount
      return isNaN(value) ? 0 : value
    },
    getTitle () {
      if (this.office && !this.isAdminPage) {
        return this.office.title
      }
      return this.userInfo.organization.title
    },
    userInfo () {
      return this.$store.state.users.info
    },
    getAmount () {
      if (this.office) {
        return this.office.amount
      }
      if (!this.organizationHeaderData) {
        return ''
      }
      if (this.isOrganizationRoot) {
        return parseFloat((this.organizationHeaderData.amount + this.organizationUser.amount).toFixed(2))
      }
      return parseFloat((this.organizationHeaderData.amount).toFixed(2))
    },
    getOrganizationAmount () {
      if (!this.organizationHeaderData) {
        return ''
      }
      return parseFloat((this.organizationUser.amount).toFixed(2))
    },
    getOrganizationOffers () {
      if (!this.organizationHeaderData) {
        return ''
      }
      return parseInt(this.organizationUser.availableOffersNumber, 10)
    },
    getOfferNumber () {
      if (this.office) {
        return this.office.availableOffersNumber
      }
      if (!this.organizationHeaderData) {
        return ''
      }
      if (this.isOrganizationRoot) {
        return this.organizationHeaderData.availableOffersNumber + this.organizationUser.availableOffersNumber
      }
      return this.organizationHeaderData.availableOffersNumber
    },
    organizationUser () {
      if (!this.$store.state.organization.organizationUser) return {}
      return this.$store.state.organization.organizationUser
    },
    organizationHeaderData () {
      return this.organizationUser.officeListData
    },
    isOrganizationRoot () {
      return userUtil.isRoot(this.userInfo)
    },


    office () {
      return this.getActiveOffice
    },
    organization () {
      return this.getMyOrganizationUser
    },
    getAddress () {
      if (!this.office) {
        return ''
      }

      if (this.office.address.length > 68) {
        return this.office.address.slice(0, 68) + '...'
      } else {
        return this.office.address
      }
    }
  },
  methods: {
    pluriaizeOffers (offerNumber) {
      return pluriaizeOffers(offerNumber)
    },
    showModalFormBuy () {
      this.$store.commit('money/selectedOffice', null)
      this.$store.dispatch('popup/showModal', { key: 'showOfferBuyForm' })
    },
    showModalTransferMoney () {
      this.$store.commit('money/selectedOffice', null)
      this.$store.dispatch('popup/showModal', { key: 'transferBalanceMoney' })
    },

    showModalOffer () {
      this.$store.commit('money/selectedOffice', null)
      this.$store.dispatch('popup/showModal', { key: 'showOfferBuyForm' })
    },
    addBalance () {
      this.$store.commit('money/selectedOffice', null)
      this.$store.dispatch('popup/showModal', { key: 'showMoneyAddForm' })
    }
  }
}
</script>

<style lang="sass" scoped>

.balance
  &-header
    @include fontStyle(800, 22px)
    color: rgba(0, 0, 0, 0.9)
    margin-right: 58px
  &-value
    display: flex
    flex-direction: column
    &__border
      padding-left: 15px
      border-left: $default_border_bottom
    &__title
      @include fontStyle(800, 15px)
      color: rgba(0, 0, 0, 0.3)

    &__val, &::v-deep .price__text
      @include fontStyle(800, 24px)
      color: black
      margin: 0

  &__title
    @include fontStyle(800, 22px)
    color: rgba(0, 0, 0, 0.9)
  &__item
    display: flex
    margin-top: 25px
    justify-content: space-between

  &__line
    display: flex

    align-items: center
    margin-bottom: 45px
  &__button
    cursor: pointer
    @include fontStyle(800, 14px)
    color: rgba(0, 0, 0, 0.71)
    height: 48px
    display: flex
    align-items: center
    &:first-child
      margin-bottom: 48px


.header-stats-wrapper
  display: flex
  flex-direction: row
  flex: 1
  height: 48px
  padding-left: 4px
  padding-right: 4px
  justify-content: space-between
  align-items: center
  border: 2px solid #8BD118
  border-radius: 24px
  margin: 0 8px
  box-sizing: border-box
  box-shadow: 10px 10px 15px rgba(117, 117, 117, 0.1)
  cursor: pointer
.admin-offer-number
  font-size: 16px
  line-height: 20px
  font-weight: 800
  color: rgba(0, 0, 0, 0.6)
  margin: 8px
.admin-header__logo
  width: 60px
  border-radius: 50%
  margin-left: 15px
  margin-right: 8px
  padding: 15px 0
  object-fit: cover

::v-deep .header__money
  @include fontStyle(800, 20px)
  cursor: pointer
  margin-right: 15px
.header__admin
  display: flex
  align-items: center
  background: #FFFFFF
  border: 1.5px solid #E3E3E3
  box-shadow: 0px 5px 15px rgba(117, 117, 117, 0.1)
  border-radius: 6px
  height: 90px

  &__count-offer
    @include fontStyle(800, 16px)
    color: rgba(0, 0, 0, 0.61)
    //margin-right: 15px
    cursor: pointer

  &__stats
    @include fontStyle(600, 12px)
    display: flex
    flex-direction: column

  &__left
    @include fontStyle(800, 20px)
    display: flex
    height: 100%
    align-items: center
    flex-grow: 1
    padding: 10px
  &__right
    display: flex
    margin-left: auto
    border-left: $default_border_bottom
    align-items: center
    padding: 10px 20px

.organization__sum
  font-size: 16px
  line-height: 20px

.stats
  &__title
    &:first-child
      margin-bottom: 5px
  &__value
    color: #8BD118
//////////
.header-stats-wrapper
  display: flex
  flex-direction: row
  height: 48px
  padding-left: 8px
  padding-right: 8px
  justify-content: flex-start
  align-items: center
  border: 2px solid #8BD118
  border-radius: 24px
  margin-left: 8px
  box-sizing: border-box
  align-self: flex-end
  box-shadow: 10px 10px 15px rgba(117, 117, 117, 0.1)
  cursor: pointer
.organization-offer-number
  font-size: 16px
  line-height: 20px
  font-weight: 800
  color: rgba(0, 0, 0, 0.6)
  margin-right: 15px
.organization__header
  display: flex
  max-height: 90px
  background: #FFFFFF
  border: $default_border_bottom
  box-shadow: 0px 5px 15px rgba(117, 117, 117, 0.1)
  border-radius: 6px
  align-items: center

.organization-header__logo
  width: 60px
  border-radius: 50%
  margin-left: 15px
  margin-right: 8px
  padding: 15px 0

.organization__header__title__text
  @include fontStyle(800, 20px)

.organization__header__address
  @include fontStyle(500, 14px)
  margin-top: 15px
  color: rgba(0, 0, 0, 0.5)
  white-space: nowrap
  text-overflow: ellipsis
  width: 435px

.organization__header__action
  display: flex
  align-items: baseline
  min-width: 430px

.organization__header__offer
  display: flex
  align-items: center
  padding: 15px 0

.organization__sum
  font-size: 20px
  line-height: 24px
  font-weight: 800
  margin-left: 20px
  margin-right: auto

.organization__offer
  font-size: 16px
  line-height: 20px
  font-weight: 800
  color: rgba(0, 0, 0, 0.6)
  margin-right: 15px

.organization__header__title
  padding: 15px 0
  padding-right: 10px
  flex-grow: 1

::v-deep .organization__header__plus__sum
  margin-left: 20px
  margin-right: 25px
  cursor: pointer
::v-deep .organization__header__plus__offer
  margin-left: 20px
  cursor: pointer

.organization__header--modal
  box-shadow: none
  border-radius: 0
  align-items: baseline
  border: 0
  border-bottom: 1px solid rgba(0, 0, 0, 0.1)

  .organization__header__address
    width: auto

  .organization__header__action
    min-width: auto

  .organization__offer
    margin-right: 0

  .organization__header__title
    border: 0
    &__text
      font-size: 16px
      line-height: 20px

  .organization__sum
    font-size: 16px
    line-height: 20px

  .organization__offer
    font-size: 14px
    line-height: 17px
</style>
